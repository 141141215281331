@layer components {
  .header-xl {
    @apply text-3xl font-bold;
  }

  .header-lg {
    @apply text-2xl font-bold;
  }

  .header-md {
    @apply text-xl font-semibold;
  }

  .header-sm {
    @apply text-base font-semibold;
  }
}
