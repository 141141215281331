@import "~cropperjs/dist/cropper.css";


.cropper-rounded {
  .cropper-face {
    border-radius: 50%;
  }
}

.cropper-modal {
  background-color: transparent;
}
