// GLOBAL STYLES
@tailwind base;

@import 'theme/_fonts';
@import 'theme/_theme';

@tailwind utilities;
@tailwind components;



@import "components/form";
@import "components/headers";
@import "components/loader";
@import "components/quill-styles";
@import "cropper";

.p-fileupload-row>div:first-child {
  display: none;
}
