@layer components {
  .form-input {
    @apply flex flex-col gap-1;
    label {
      @apply font-semibold;
    }
  }

  .field-checkbox {
    @apply flex gap-2 items-center
  }

  .valid-input {
    @apply border border-green-600 rounded-lg;
    input {
      @apply border-none;
    }
  }

  .link {
    @apply text-[#048aeb] cursor-pointer;
  }

  .error {
    @apply text-red-600;
  }

  .ng-submitted {
    .form-input {
      input.ng-invalid {
        border-color: red;
      }
    }
  }

  .default-column-form {
    @apply flex flex-col gap-4 w-[80%] sm:w-[30rem];
  }

  .login-column-form {
    @apply flex flex-col gap-4 w-full sm:w-[30rem] ;
  }
}

